import React from "react";
import Image from "gatsby-image";
import Slider from "react-slick";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowCircleLeft,
  faArrowCircleRight
} from "@fortawesome/free-solid-svg-icons";
import styles from "./style.module.scss";

const NextArrow = ({ onClick }) => {
  return (
    <div className={styles.next} onClick={onClick}>
      <FontAwesomeIcon className={styles.icon} icon={faArrowCircleRight} />
    </div>
  );
};

const PrevArrow = ({ onClick }) => {
  return (
    <div className={styles.prev} onClick={onClick}>
      <FontAwesomeIcon className={styles.icon} icon={faArrowCircleLeft} />
    </div>
  );
};

const sliderOptions = {
  nextArrow: <NextArrow />,
  prevArrow: <PrevArrow />,
  infinite: true,
  speed: 1500,
  autoplaySpeed: 5000,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true
};

const MiniGallery = ({ images }) => {
  return (
    <Slider {...sliderOptions}>
      {images.map(({ fluid, id, title }) => (
        <Image alt={title} key={id} fluid={fluid} />
      ))}
    </Slider>
  );
};

export default MiniGallery;
