import React from "react";
import PropTypes from "prop-types";

import { graphql } from "gatsby";
import Markdown from "../../components/Markdown";
import SEO from "../../components/SEO";
import { Container, Section } from "../../components/LayoutUtils";
import IntroHeader from "../../components/IntroHeader";
import Layout from "../../layouts/index";
import PriceList from "../../components/PriceList";
import MiniGallery from "../../components/MiniGallery";
import getPageImage from "../../utils/getPageImage";

const PricingPage = ({ data }) => (
  <Layout>
    <SEO
      image={getPageImage(data.contentfulPage)}
      title="Our prices"
      description="Price list for our handmade bespoke garments. The price of a garment depends mainly on the cloth you choose."
    />
    <IntroHeader {...data.contentfulPage} />
    <Container>
      <Section>
        <div className="row">
          <div className="col-md-6">
            <Markdown source={data.contentfulPage.body.body} />
            <MiniGallery images={data.contentfulPage.images} />
          </div>
          <div className="col-md-6">
            <PriceList />
          </div>
        </div>
      </Section>
    </Container>
  </Layout>
);

PricingPage.propTypes = {
  data: PropTypes.shape({
    contentfulPage: PropTypes.shape({})
  }).isRequired
};

export const query = graphql`
  {
    contentfulPage(slug: { eq: "pricing" }) {
      ...page
    }
  }
`;

export default PricingPage;
